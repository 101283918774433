/**
 * @file Comment state
 * @module store.comment
 * @author Surmon <https://github.com/surmon-china>
 */

import nodepress from '/@/services/nodepress'
import { Comment, CommentFetchParams } from '/@/stores/comment'

export const COMMENT_API_PATH = '/comment'

export const getCommentList = (params: CommentFetchParams) => {
  return nodepress.get(`${COMMENT_API_PATH}/${params.postId}`, { params })
}

export const postComment = (comment: Partial<Comment>) => {
  return nodepress.post<Comment>('/comment', comment)
}

export const deleteComment = (commentId: string) => {
  return nodepress.delete('/comment', { commentId })
}

export const postCommentVote = (data: any = {}) => {
  return nodepress
    .post(`/comment/${data.comment_id}/vote`, data)
}
