/**
 * @file Categories state
 * @module store.category
 * @author Surmon <https://github.com/surmon-china>
 */

import { defineStore } from 'pinia'
import { UniversalKeyValue } from '/@/constants/state'
import { getAllCategory } from '/@/api/category'
import { GeneralPaginateQueryParams } from '/@/api/request'

export interface Category {
  id: string
  pid: string
  name: string
  slug: string
  description: string
  update_at: string
  create_at: string
  extends: UniversalKeyValue[]
  articles_count: number
}

export interface CategorySearchParams extends GeneralPaginateQueryParams {
}

export const useCategoryStore = defineStore('category', {
  state: () => ({
    fetched: false,
    fetching: false,
    categories: [] as Array<Category>
  }),
  actions: {
    fetchAll(params?: CategorySearchParams) {
      if (this.fetched) {
        return Promise.resolve()
      }

      this.fetching = true
      return getAllCategory(params = { pageNo: 1, pageSize: 50 })
        .then((response) => {
          this.categories = response.data.list
          this.fetched = true
        })
        .finally(() => {
          this.fetching = false
        })
    }
  }
})
