/**
 * @file App config
 * @module config.app
 * @author Surmon <https://github.com/surmon-china>
 */

export const DEFAULT_DELAY = 468
export const LONG_ARTICLE_THRESHOLD = 16688
export const PRIMARY_COLOR = '#0088f5'
export const GA_MEASUREMENT_ID = 'G-STXP1W69HK'
export const ADSENSE_CLIENT_ID = ''

export const META = Object.freeze({
  title: 'Ximplez',
  zh_sub_title: '与光同尘',
  en_sub_title: 'Because the mountain is there',
  domain: 'ximplez.com',
  url: 'https://ximplez.com',
  author: 'Ximplez'
})

export const THIRD_IDS = Object.freeze({
  YOUTUBE_CHANNEL_ID: ``,
  MUSIC_163_BGM_ALBUM_ID: '',
  GITHUB_USER_ID: 'ximplez',
  TWITTER_USER_ID: '',
  INSTAGRAM_USERNAME: '',
  INSTAGRAM_FB_ID: ''
})

export const SPONSOR_LINKS = Object.freeze({
  PAYPAL: '',
  GITHUB_SPONSORS: '',
  BTC_ADDRESS: '',
  ETH_ADDRESS: ''
})

export const VALUABLE_LINKS = Object.freeze({
  RSS: '/rss.xml',
  SITE_MAP: '/sitemap.xml',

  GITHUB_SURMON_ME: 'https://github.com/ximplez',
  GITHUB_NODEPRESS: 'https://github.com/ximplez',
  GITHUB_SURMON_ME_NATIVE: 'https://github.com/ximplez',
  GITHUB_BLOG_STAR_LIST: 'https://github.com/ximplez',
  APP_APK_FILE:
    'https://github.com/ximplez',

  THROW_ERROR: 'https://throwerror.io',
  FOX_FINDER: 'https://foxfinder.io',
  GITHUB: 'https://github.com/ximplez',
  GITHUB_SPONSORS: 'https://github.com/sponsors/ximplez',
  PAYPAL: '',
  UPWORK: 'https://www.upwork.com/freelancers/~0142e621258ac1770d',
  MARKDOWN: 'https://daringfireball.net/projects/markdown/',

  GOOGLE_LIVE_MAP: 'https://www.google.com/maps/d/embed?mid=1sRx6t0Yj1TutbwORCvjwTMgr70r62Z6w&z=3',
  QQ_GROUP:
    '',
  TELEGRAM: 'https://t.me/ximplez',
  TELEGRAM_GROUP: '',
  SPOTIFY: '',
  MUSIC_163: `${THIRD_IDS.MUSIC_163_BGM_ALBUM_ID}`,
  YOUTUBE_CHANNEL: `${THIRD_IDS.YOUTUBE_CHANNEL_ID}`,
  DOUBAN: '',
  ZHIHU: '',
  WEIBO: '',
  QUORA: '',
  STACK_OVERFLOW: '',
  LEETCODE_CN: '',
  LINKEDIN: '',
  INSTAGRAM: `${THIRD_IDS.INSTAGRAM_USERNAME}`,
  TWITTER: `${THIRD_IDS.TWITTER_USER_ID}`
})
