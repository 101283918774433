/**
 * @file Tags state
 * @module store.tag
 * @author Surmon <https://github.com/surmon-china>
 */

import nodepress from '/@/services/nodepress'
import { TagSearchParams } from '/@/stores/tag'

export const getTagList = (params: TagSearchParams) => {
  return nodepress
    .get('/tag', { params })
}
