/**
 * @file Archive state
 * @module store.archive
 * @author Surmon <https://github.com/surmon-china>
 */

import nodepress from '/@/services/nodepress'
import { Archive, Statistic } from '/@/stores/archive'

export const getArchive = () => {
  return nodepress.get<Archive>('/archive')
}

export const getStatistic = () => {
  return nodepress.get<Statistic>('/expansion/statistic')
}

export const getCalendar = () => {
  return nodepress.get('/archive/calendar')
}
