
/** 通用请求参数 */
export interface GeneralQueryParams {
  [key: string]: number | string | boolean | void
}

/** 通用翻页请求参数 */
export interface GeneralPaginateQueryParams extends GeneralQueryParams {
  pageNo: number
  pageSize: number
}

export enum Scene {
  // 文章相关
  // 基础信息 = 展示信息 + 控制信息
  ARTICLE_BASE = 10,
  // 基础信息 + 内容
  ARTICLE_WITH_CONTENT = 11,
  // 基础信息 + 目录基础 + 标签基础
  ARTICLE_WITH_CATEGORY_TAG = 12,
  // 基础信息 + 目录基础 + 标签基础 + 附属元数据
  ARTICLE_WITH_CATEGORY_TAG_META= 13,
  // 所有数据
  ARTICLE_ALL_DATA = 14
}

/** 翻页参数 */
export interface Pagination {
  total: number
  current: number
  pages: number
  size: number
}
