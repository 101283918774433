/**
 * @file Comment state
 * @module store.comment
 * @author Surmon <https://github.com/surmon-china>
 */

import { defineStore } from 'pinia'
import { isClient } from '/@/app/environment'
import { UNDEFINED } from '/@/constants/value'
import { SortType, UniversalKeyValue, CommentParentType } from '/@/constants/state'
import { delayPromise } from '/@/utils/delayer'
import { useUniversalStore } from './universal'
import { deleteComment, getCommentList, postComment, postCommentVote } from '/@/api/comment'
import { GeneralPaginateQueryParams } from '@/api/request'

export const COMMENT_EMPTY_PID = CommentParentType.Self

export interface Author {
  name: string
  site?: string
  email?: string
  email_hash?: string
}

export interface IPLocation {
  country: string
  country_code: string
  region: string
  region_code: string
  city: string
  zip: string
}

export interface Comment {
  postId: string
  id: string
  pid: string | typeof COMMENT_EMPTY_PID
  content: string
  agent?: string
  author: Author
  likes: number
  dislikes: number
  meta: {
    likes: number
    dislikes: number
  }
  ip?: string
  ip_location?: IPLocation
  create_at: string
  update_at: string
  extends: UniversalKeyValue[]
}
export interface CommentFetchParams extends GeneralPaginateQueryParams {
  postId?: string
  sort?: SortType
  loadmore?: boolean
}

export interface CommentTreeItem {
  comment: Comment
  children: Array<Comment>
}

export const useCommentStore = defineStore('comment', {
  state: () => ({
    fetching: false,
    posting: false,
    deleting: false,
    comments: [] as Array<Comment>,
    pagination: null as null | $TODO
  }),
  getters: {
    commentTreeList: (state): Array<CommentTreeItem> => {
      // only keep 2 level tree
      const ids = state.comments.map((comment) => comment.id)
      const roots = state.comments.filter(
        (comment) => comment.pid === COMMENT_EMPTY_PID || !ids.includes(comment.pid)
      )
      const children = state.comments.filter(
        (comment) => comment.pid !== COMMENT_EMPTY_PID && ids.includes(comment.pid)
      )
      const fullMap = new Map<string, Comment>(
        state.comments.map((comment) => [comment.id, comment])
      )
      const treeMap = new Map<string, { comment: Comment; children: Array<Comment> }>(
        roots.map((comment) => [comment.id, { comment, children: [] }])
      )

      const findRootParentID = (pid: string): string | void => {
        const target = fullMap.get(pid)
        if (!target) {
          return UNDEFINED
        }
        return target.pid === COMMENT_EMPTY_PID ? target.id : findRootParentID(target.pid)
      }

      children.forEach((comment) => {
        const rootPID = findRootParentID(comment.pid)
        if (rootPID) {
          if (treeMap.has(rootPID)) {
            const target = treeMap.get(rootPID)!
            treeMap.set(rootPID, {
              ...target,
              children: [comment, ...target.children]
            })
          }
        }
      })
      return Array.from(treeMap.values())
    }
  },
  actions: {
    clearList() {
      this.comments = []
      this.pagination = null
    },

    fetchList(params: CommentFetchParams = {pageNo: 1, pageSize: 50}) {
      params = {
        sort: SortType.Desc,
        loadmore: false,
        ...params
      }

      // clear list when refetch
      if (params.page === 1) {
        this.clearList()
      }
      this.fetching = true
      const fetch = getCommentList(params)
      const promise = isClient ? delayPromise(480, fetch) : fetch
      return promise
        .then((response) => {
          this.pagination = response.data.pagination
          if (params.loadmore) {
            this.comments.push(...response.data.list)
          } else {
            this.comments = response.data.list
          }
        })
        .finally(() => {
          this.fetching = false
        })
    },

    postComment(comment: Partial<Comment>) {
      this.posting = true
      return postComment(comment)
        .then((response) => {
          this.comments.unshift(response.data)
          if (this.pagination) {
            this.pagination.total++
          }
          return response.data
        })
        .finally(() => {
          this.posting = false
        })
    },

    deleteComment(commentId: string) {
      this.deleting = true
      return deleteComment(commentId)
        .then(() => {
          const index = this.comments.findIndex((comment) => comment.id === commentId)
          if (index > -1) {
            this.comments.splice(index, 1)
            this.pagination.total--
          }
        })
        .finally(() => {
          this.deleting = false
        })
    },

    postCommentVote(commentID: string, vote: 1 | -1) {
      const universalStore = useUniversalStore()
      return postCommentVote({ comment_id: commentID, vote, author: universalStore.author }).then(
        () => {
          const comment = this.comments.find((comment) => comment.id === commentID)
          if (comment) {
            vote > 0 ? comment.meta.likes++ : comment.meta.dislikes++
          }
        }
      )
    }
  }
})
