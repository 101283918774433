/**
 * @file Universal state
 * @module store.universal
 * @author Surmon <https://github.com/surmon-china>
 */

import nodepress from '/@/services/nodepress'

export const getDisqusLayout = () => {
  return nodepress.get('/disqus/oauth-logout')
}

export const getDisqusUserInfo = () => {
  return nodepress.get<any>('/disqus/user-info')
}

export const getDisqusConfig = () => {
  return nodepress.get<string>('/disqus/config')
}
