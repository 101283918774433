/**
 * @file App meta options state
 * @module store.meta
 * @author Surmon <https://github.com/surmon-china>
 */

import nodepress from '/@/services/nodepress'

export const getAdminInfo = () => {
  return nodepress
    .get('/auth/admin')
}

export const getAppOptions = () => {
  return nodepress
    .get('/option')
}

export const postSiteLike = (data: any = {}) => {
  return nodepress.post('/vote/site', data)
}
