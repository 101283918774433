/**
 * @file Announcement state
 * @module stores.announcement
 * @author Surmon <https://github.com/surmon-china>
 */

import { defineStore } from 'pinia'
import { getAnnouncement } from '/@/api/announcement'
import { GeneralPaginateQueryParams } from '/@/api/request'

export interface AnnouncementSearchParams extends GeneralPaginateQueryParams {
}

export const useAnnouncementStore = defineStore('announcement', {
  state: () => ({
    fetching: false,
    announcements: [] as Array<$TODO>
  }),
  actions: {
    fetchList(params?: AnnouncementSearchParams) {
      this.announcements = []
      this.fetching = true
      return getAnnouncement(params || { pageNo:1, pageSize: 50 })
        .then((response) => {
          this.announcements = response.data.list
        })
        .finally(() => {
          this.fetching = false
        })
    }
  }
})
