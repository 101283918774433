/**
 * @file Categories state
 * @module store.category
 * @author Surmon <https://github.com/surmon-china>
 */

import nodepress from '/@/services/nodepress'
import { CategorySearchParams } from '/@/stores/category'

export const getAllCategory = (params: CategorySearchParams) => {
  return nodepress
    .get('/category', { params })
}
