/**
 * @file Announcement state
 * @module store.announcement
 * @author Surmon <https://github.com/surmon-china>
 */

import nodepress from '/@/services/nodepress'
import { AnnouncementSearchParams } from '/@/stores/announcement'

export const getAnnouncement = (params?: AnnouncementSearchParams) => {
  return nodepress
    .get('/announcement', { params })
}
