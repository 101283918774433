/**
 * @file Universal state
 * @module store.universal
 * @author Surmon <https://github.com/surmon-china>
 */

import { defineStore } from 'pinia'
import { getJSON, setJSON, remove } from '/@/utils/storage'
import { Author } from './comment'
import { getDisqusConfig, getDisqusLayout, getDisqusUserInfo } from '/@/api/universal'

export type UserLocalProfile = Author
export enum UserType {
  Null = 0,
  Local = 1,
  Disqus = 2
}

const UNIVERSAL_STORAGE_KEY = 'universal-state'
export const useUniversalStore = defineStore('universal', {
  state: () => ({
    // disqus config
    disqusConfig: null as any,
    // user
    user: {
      type: UserType.Null,
      localProfile: null as UserLocalProfile | null,
      disqusProfile: null as any | null
    },
    // vote history
    vote: {
      likedPages: [] as string[],
      likedComments: [] as string[],
      dislikedComments: [] as string[]
    },
    // feedback history
    feedbacks: [] as any[]
  }),
  getters: {
    isLikedPage: (state) => (id: string) => state.vote.likedPages.includes(id),
    isLikedComment: (state) => (id: string) => state.vote.likedComments.includes(id),
    isDislikedComment: (state) => (id: string) => state.vote.dislikedComments.includes(id),
    author: (state): Author | null => {
      if (state.user.type === UserType.Local) {
        return state.user.localProfile
      }
      if (state.user.type === UserType.Disqus) {
        return {
          name: state.user.disqusProfile?.name,
          site: state.user.disqusProfile?.url || state.user.disqusProfile?.profileUrl
        }
      }
      return null
    }
  },
  actions: {
    likeComment(commentID: string) {
      this.vote.likedComments.push(commentID)
    },
    dislikeComment(commentID: string) {
      this.vote.dislikedComments.push(commentID)
    },
    likePage(id: string) {
      this.vote.likedPages.push(id)
    },
    addFeedback(data) {
      this.feedbacks.push(data)
    },
    fromStorage() {
      try {
        this.$state = getJSON(UNIVERSAL_STORAGE_KEY) || this.$state
      } catch (error) {
        remove(UNIVERSAL_STORAGE_KEY)
      }
    },
    initOnClient() {
      // 1. bind client storage
      this.fromStorage()
      this.$subscribe(() => setJSON(UNIVERSAL_STORAGE_KEY, this.$state))
      window.addEventListener('storage', (event) => {
        if (event.key === UNIVERSAL_STORAGE_KEY) {
          this.fromStorage()
        }
      })
      // 2. init and reset user info
      // if (this.$state.user.type === UserType.Disqus) {
      //   this.fetchDisqusUserInfo().catch(() => {
      //     this.user.disqusProfile = null
      //     this.user.type = UserType.Null
      //   })
      // } else {
      //   this.user.disqusProfile = null
      // }
      // 3. refetch disqus config
      // this.fetchDisqusConfig()
    },
    saveLocalUser(user: UserLocalProfile) {
      this.user.localProfile = { ...user }
      this.user.type = UserType.Local
    },
    removeLocalUser() {
      this.user.localProfile = null
      this.user.type = UserType.Null
    },
    fetchDisqusLogout() {
      return getDisqusLayout().then(() => {
        this.user.disqusProfile = null
        this.user.type = UserType.Null
      })
    },
    fetchDisqusUserInfo() {
      return getDisqusUserInfo().then((response) => {
        this.user.disqusProfile = response.data
        this.user.type = UserType.Disqus
      })
    },
    fetchDisqusConfig() {
      return getDisqusConfig().then((response) => {
        this.disqusConfig = response.data
      })
    }
  }
})
