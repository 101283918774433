/**
 * @file Article state
 * @module store.article
 * @author Surmon <https://github.com/surmon-china>
 */
import nodepress from '/@/services/nodepress'
import { ArticleSearchParams } from '/@/stores/article'

export const ARTICLE_API_PATH = '/article'

// 获取文章列表
export const getArticleList = (params: ArticleSearchParams) => {
  return nodepress.get<any>(ARTICLE_API_PATH, { params })
}
// 获取相关文章
export const getRelatedArticles = (articleID: string) => {
  return nodepress.get(`${ARTICLE_API_PATH}/${articleID}/context`)
}

// 获取最热文章列表
export const getHotArticleList = (params: ArticleSearchParams) => {
  return nodepress
    .get(ARTICLE_API_PATH, { params })
}

// 获取文章详情
export const getArticleDetail = (articleID: any) => {
  return nodepress.get(`${ARTICLE_API_PATH}/${articleID}`)
}

// 喜欢文章
export const postArticleLike = (data: any) => {
  return nodepress
    .post(`/article/${data.article_id}/vote`, data)
}
